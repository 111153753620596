import React from "react"
import ContactSection from "../components/home/ContactSection"
import Layout from "../components/layout"
import bg from "../images/neoPool.jpg"
import { Typography, CardMedia, Container } from "@mui/material"
import { Box } from "@mui/system"

const NeoPool = () => {
  return (
    <Layout>
      {/* <CardMedia 
 component="img"  image={props.image} alt="Paella dish"
></CardMedia> */}

      <Box sx={{ height:{ xs:"70px",md:"130px"}, backgroundColor: "#131418" }}></Box>

      <Box
        sx={{
          width: "100%",
          top: "0",
          position: "relative",
          olor: "#131418",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0",
            fontWeight: "600",
            width: "100%",
          }}
        >
          <Typography
            variant="h2"
            component="h2"
            sx={{
              fontFamily: "clash display, sans-serif",
              fontSize: { xs: "1.5rem", md: "3rem" },
              color: "white",
              margin: "20px auto",
              textAlign: "center",
              lineHeight: "150%",
            }}
          >
            NeoPool
          </Typography>
          <Typography
            variant="h3"
            component="h3"
            sx={{
              fontSize: { xs: "1rem", md: "1rem" },
              color: "white",
              margin: "5px auto",
              textAlign: "center",
              maxWidth: "500px",
            }}
          >
            NeoPool is a Web first real time AR collaboration platform without addional devices. It can
            work cross mobile, desktop, browser, and AR/VR devices.
          </Typography>

          <Typography
            variant="h3"
            component="h3"
            sx={{
              fontSize: { xs: "2rem", md: "2rem" },
              color: "white",
              margin: "20px auto",
              textAlign: "center",
              maxWidth: "450px",
            }}
          >
            Coming Soon
          </Typography>
        </Box>

        <CardMedia
          component="img"
          image={bg}
          alt="Neoxr neo pool"
          sx={
            {
              // filter: "opacity(25%)",
            }
          }
        ></CardMedia>
      </Box>
    </Layout>
  )
}

export default NeoPool
